let listenerAttached = false;
let tabVisible = false;
let intervalId: NodeJS.Timeout | null = null;
let lastActivityReport: number | null = null;
const activityReportThreshold = 1000 * 60 * 2; // 2 minute

function reportActivity() {
  const store = useStore();
  if (store.isLoggedIn) {
    const now = Date.now();
    if (!lastActivityReport || now - lastActivityReport > activityReportThreshold) {
      API.notifyActivity();
      lastActivityReport = now;
    }
  }
}

export function periodicallyReportActivity() {

  if (!process.client) return;

  // Report activity immediately on page load
  reportActivity();

  if (!listenerAttached) {
    document.addEventListener('visibilitychange', () => {
      tabVisible = document.visibilityState === 'visible';
  
      // If the tab is visible, report activity immediately
      if (tabVisible) reportActivity();
    });
    listenerAttached = true;
  }

  // Report activity every 5 minutes
  if (!intervalId) {
    intervalId = setInterval(() => {
      if (!tabVisible) return;
      reportActivity();
    }, 1000 * 60 * 5);  
  }
}