<script lang="ts" setup>
import { discord, api } from "@/assets/svg/icons";
import {
  ArrowLeftOnRectangleIcon,
  CreditCardIcon,
  InboxIcon,
  WrenchIcon,
  LifebuoyIcon,
  PhoneIcon,
  PlusIcon,
  ShoppingCartIcon,
  TicketIcon,
  UserGroupIcon,
} from '@heroicons/vue/24/outline';

const store = useStore();
</script>

<template>

  <header class="flex flex-col justify-start w-full h-full pb-20">

    <div class="inline-flex space-x-4 mb-6 animate-fade-in">

      <NuxtLink to="/">
        <Logo class="h-5"/>
      </NuxtLink>

      <ThemeSwitch />

    </div>

    <!-- <UButton
      color="white"
      size="2xs"
      role="navigation"
      class="mb-10"
      block
      @click="navigateTo('/subscription')">
      <ShoppingCartIcon class="h-5 w-5 mr-2 stroke-yellow-500" />
      Get more lines
      <PlusIcon class="h-4 w-4 ml-1 stroke-gray-900 dark:stroke-white" />
    </UButton> -->

    <nav class="inline-flex flex-col items-center">

      <ol class="inline-flex flex-col w-full">

        <div class="space-y-1 mb-10">

          <li>
            <NuxtLink
              to="/"
              active-class="active">
              <InboxIcon />
              Messages
            </NuxtLink>
          </li>

          <li>
            <NuxtLink
              to="/lines"
              active-class="active">
              <PhoneIcon />
              Lines
            </NuxtLink>
          </li>

          <!-- <li>
            <NuxtLink
              to="/tickets"
              active-class="active"
              style="animation-delay: 50ms;">
              <TicketIcon />
              Tickets
            </NuxtLink>
          </li> -->

          <!-- <li>
            <NuxtLink
              to="/billing"
              active-class="active"
              style="animation-delay: 70ms;">
              <CreditCardIcon />
              Billing
            </NuxtLink>
          </li> -->

          <!-- <li>
            <NuxtLink
              to="/team"
              active-class="active"
              style="animation-delay: 90ms;">
              <UserGroupIcon />
              Team
            </NuxtLink>
          </li> -->
          <li>
            <NuxtLink
              to="/developers"
              active-class="active"
              style="animation-delay: 110ms;">
              <i v-html="api" />
              Developers
            </NuxtLink>
          </li>

          <li v-if="store.userIsAdmin">
            <NuxtLink
              to="/admin/telemetry"
              active-class="active"
              style="animation-delay: 110ms;">
              <WrenchIcon />
              Admin
            </NuxtLink>
          </li>

        </div>


        <!-- <li>
          <NuxtLink
            to="/help"
            active-class="active"
            style="animation-delay: 130ms;">
            <LifebuoyIcon />
            Help
          </NuxtLink>
        </li> -->

        <!-- <li>
          <NuxtLink
            to="https://guiderail.canny.io/"
            target="_blank"
            active-class="active">
            <ChatBubbleLeftRightIcon />
            Feedback
          </NuxtLink>
        </li> -->

        <li>
          <NuxtLink
            to="https://discord.gg/KPhmYZnZNy"
            target="_blank"
            active-class="active"
            style="animation-delay: 130ms;">
            <i v-html="discord" />
            Discord
          </NuxtLink>
        </li>

        <li class="mt-20">

          <button @click="store.logout">
            <ArrowLeftOnRectangleIcon />
            Logout
          </button>

        </li>

      </ol>

    </nav>

    <div class="flex flex-col items-start justify-end flex-1">
      <UButton
        to="/terms"
        variant="link"
        target="_blank"
        class="text-gray-500 font-normal"
        size="xs">Terms of Service</UButton>
      <UButton
        to="/privacy"
        variant="link"
        target="_blank"
        class="text-gray-500 font-normal"
        size="xs">Privacy Policy</UButton>
    </div>

  </header>

</template>

<style scoped>
li {
  @apply space-y-2;
}

li a {
  @apply no-underline;
}

li a, li button {
  @apply
    animate-fade-in-up opacity-0
    w-full
    transition-all
    inline-flex items-center
    py-1 pl-2 pr-6
    rounded-md
    text-sm
    text-gray-900 dark:text-white font-medium
    hover:bg-gray-200 dark:hover:bg-gray-700;
}

li a.active {
  @apply
    bg-gray-700 dark:bg-white
    text-white dark:text-black;
}

li a.active i, li button i {
  @apply fill-white dark:fill-black;
}

li a.active svg, li button svg {
  @apply stroke-white dark:stroke-black;
}

li a svg, li button svg {
  @apply h-5 w-5 mr-2 stroke-gray-700 dark:stroke-white;
}

li a i, li button i {
  @apply h-5 w-5 mr-2 fill-gray-700 dark:fill-white;
}

</style>