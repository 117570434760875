<script lang="ts" setup>

/**
 * We only connect the websocket once the user
 * have been authenticated. Hence why it's in the
 * 'inside' layout which is the one used for all
 * the pages that require authentication.
 */
useWebsocket();
periodicallyReportActivity();
</script>

<template>

  <!-- <WarningNotice class="fixed z-0 top-0 left-0 mb-10 w-full animate-fade-in opacity-0" /> -->

  <div>

    <div class="flex flex-row max-w-4xl w-full mx-auto">

      <div class="sticky top-0 h-screen pt-20 pr-10">
        <AppHeaderCol />
      </div>

      <main class="flex-1">
        <slot />
      </main>

    </div>

  </div>

</template>
